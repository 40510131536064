<template>
    <div class="bg-white-pure list-wrapper">
        <div class="flex pb-8 items-center w-full justify-between">
            <div class="flex items-center">
                <IconSvg class="text-primary-light w-10 mr-2" />
                <h1 class="text-black mr-2 text-xl lg:text-2xl">
                    Stuni Workshops
                </h1>
            </div>
        </div>

        <div class="flex items-center mb-6">
            <fa-icon icon="filter" class="tablet-hide text-sm text-black mr-4" />
            <LocationProgramFilter
                class="flex mr-2"
                dense
                single-line
                hide-details
                outlined
                :user-scoped="true"
                :show-location="false"
                :show-only-stuni="true"
                v-model="filter.locationProgram"
                @change="filterChangeHandler"
            />
            <GroupFilter @setGroupFilter="setGroupFilter"/>
            <v-btn
                depressed
                class="mr-4"
                @click="resetFiltersHandler"
            >Reset</v-btn>
        </div>

        <div class="a-user-list">
            <div class="flex flex-row">
                <div class="flex items-center w-full">
                    <!--
                        for now we just allow delete, but need a thing later using casl
                        :isDeletable="$can('delete', 'analysis_result')"
                    -->
                    <StuniWorkshopsTable
                        ref="userTable"
                        :table-data="workshops"
                        :users="filteredStaff"
                        :client-names="filteredClientNames"
                        :offset="pagination.offset"
                        :limit="pagination.limit"
                        :sort="sort"
                        :total="total"
                        :loading="loading"
                        :isDeletable="true"
                        :is-admin="true"
                        :location-program-id="filter.locationProgram.locationProgramId"
                        @update-pagination="updatePagination"
                        @reload-workshops="getWorkshops"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted, computed, watch } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import GroupFilter from '@/components/groups/GroupFilter'
import { useGroupFilterStore } from '@/stores/useGroupFilterStore'
import {mapState} from 'vuex'
import StuniWorkshopsTable from '@/components/stuni/partials/StuniWorkshopsTable'
import IconSvg from '@/assets/icons/nav/icons_cog.svg'
import LocationProgramFilter from '@/components/shared/LocationProgramFilter'
import {UserHelper} from '@/components/shared/mixins/userMixin'
import {StuniHelper} from '@/components/shared/mixins/stuniMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { workshopService } from '@/services/workshop.service'
import { userService } from '@/services/user.service'
import { useWorkshopStore } from '@/stores/useWorkshopStore'

const DEFAULT_PAGINATION = {
    offset: 0,
    limit: 10
}
const DEFAULT_FILTER = {
    locationProgram: {
        locationId: null,
        locationProgramId: null
    },
    startDate: null,
    endDate: null,
}
const DEFAULT_SORT = ['firstName desc']

export default {
    name: 'StuniWorkshops',
    components: {StuniWorkshopsTable, IconSvg, LocationProgramFilter, GroupFilter},
    mixins: [UserHelper, MessageDialog, StuniHelper],
    data() {
        return {
            loading: false,
            users: [],
            total: 0,
            pagination: {...DEFAULT_PAGINATION},
            sort: [...DEFAULT_SORT],
            filter: {
                ...DEFAULT_FILTER,
                locationProgram: {...DEFAULT_FILTER.locationProgram}
            }
        }
    },
    methods: {
        updatePagination({page, itemsPerPage, sortBy, sortDesc}) {
            if (itemsPerPage * (page - 1) > this.total) return

            this.pagination.limit = itemsPerPage
            this.pagination.offset = this.pagination.limit * (page - 1)

            this.sort = sortBy.map((fieldName, i) => `${fieldName}${sortDesc[i] ? ' desc' : ''}`)

            this.updateRoute()
        },
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections
        }),
        userStatuses() {
            return this.staticChoices['user.statuses']
        },
        userRoles() {
            return this.staticChoices['user.roles']
        },
        orderBy() {
            let orderBy = {}
            if (this.sort?.length) {
                this.sort.forEach((sortOne) => {
                    const [fieldName, sortDir] = sortOne.split(' ')
                    orderBy[fieldName] = sortDir === 'desc' ? 'desc_nulls_last' : 'asc_nulls_first'
                })
            }
            if (!orderBy.userId) orderBy.userId = 'asc_nulls_first'

            return orderBy
        }
    },
    setup() {
        const groupFilterStore = useGroupFilterStore()
        const { setResetDateFilter } = groupFilterStore
        const workshopStore = useWorkshopStore()
        const { updateWorkshopDeleted } = workshopStore
        const { workshopDeleted } = storeToRefs(workshopStore)

        const getWorkshops = async () => {
            const data = await workshopService.getWorkshops(
                filter.value?.locationProgram?.locationProgramId,
                filter.value.startDate,
                filter.value.endDate
            )
            data.forEach((workshop) => {
                workshop.attendees = workshop.attendees?.split(',')
                    ?.filter(a => !!a)
                    ?.map((id) => parseInt(id)) || []
            })
            state.workshops = [...data]
        }
        const filter = ref({
            ...DEFAULT_FILTER,
            locationProgram: {...DEFAULT_FILTER.locationProgram}
        })
        const state = reactive({
            loading: false,
            workshops: [],
            staff: [],
            clientNames: [],
            pagination: {...DEFAULT_PAGINATION},
            sort: [...DEFAULT_SORT],
            dateRange: {}
        })

        const resetFiltersHandler = () => {
            filter.value.locationProgram = {
                locationId: null,
                locationProgramId: null
            }
            filter.value.startDate = null
            filter.value.endDate = null
            setResetDateFilter(true)
        }

        const resetPage = () => {
            state.pagination.offset = 0
        }

        const filterChangeHandler = async (changes) => {
            const tempLocationProgram = { ...filter.value.locationProgram, ...changes }
            filter.value = {...filter.value, locationProgram: { ...tempLocationProgram }}
            await getWorkshops()
        }

        const setGroupFilter = async (dataFilter) => {
            const tempFilter = {...filter.value}
            tempFilter.startDate = dataFilter.startDate
            tempFilter.endDate = dataFilter.endDate

            filter.value = {...tempFilter}
            await getWorkshops()
        }

        const getStaff = async () => {
            const data = await userService.getStaffUsers()
            state.staff = [...data]
        }

        const filteredStaff = computed(() => {
            let data = [...(state.staff || [])]
            if (filter.value?.locationProgram?.locationProgramId) {
                data = data.filter(
                    (staff) => staff.locationPrograms.some(lp => lp.locationProgramId === filter.value.locationProgram.locationProgramId)
                )
            }
            return data
        })

        const getClientNames = async () => {
            const data = await userService.getClientNames()
            state.clientNames = [...data]
        }

        const filteredClientNames = computed(() => {
            let data = [...(state.clientNames || [])]
            if (filter.value?.locationProgram?.locationProgramId) {
                data = data.filter(
                    (client) => client?.locationProgramIds?.indexOf(filter.value.locationProgram.locationProgramId) > -1
                )
            }
            return data
        })

        watch(workshopDeleted, async (newVal) => {
            if (newVal) {
                await getWorkshops()
                updateWorkshopDeleted(false)
            }
        })
        onMounted(async () => {
            await getWorkshops()
            await getStaff()
            await getClientNames()
        })

        return {
            ...toRefs(state),
            filter,
            filteredStaff,
            filteredClientNames,
            getWorkshops,
            setGroupFilter,
            resetFiltersHandler,
            resetPage,
            filterChangeHandler
        }
    }
}
</script>
