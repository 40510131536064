<template>
    <div class="c-analysis-result__table editable_table flex flex-col w-full">
        <v-data-table
            ref="workshopsTable"
            single-select
            :item-key="itemKey"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :footer-props="footerProps"
            :item-class="(item) => (item.active ? 'v-data-table__selected' : '')"
            class="a-table w-full"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :no-data-text="emptyText"
            :no-results-text="emptyText"
            @click:row="rowClickHandler"
            @item-selected="itemSelected"
        >
            <template class="" v-slot:item.date="{item}">
                <DatePickerCell
                    :ref="`date-${item.workshopId}`"
                    v-model="item.date"
                    :disabled="disabled"
                    :isRequired="true"
                    label="dd/mm/yyyy"
                    :rules="[required]"
                    :cell-id="item.workshopId"
                    :edit-active="item.active"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.time="{item}">
                <DatetimePickerCell
                    :ref="`startTime-${item.workshopId}`"
                    :edit-active="item.active"
                    :cell-id="item.workshopId"
                    v-model="item.time"
                    :disabled="disabled"
                    label="00:00 AM"
                    :isRequired="true"
                    type="time"
                    :format="timeFormat"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.type="{item}">
                <SelectCell
                    :ref="`group-${item.workshopId}`"
                    :edit-active="item.active"
                    :items="types"
                    label="Type"
                    :editableComponent="'v-autocomplete'"
                    :isRequired="true"
                    :rules="[required]"
                    v-model="item.type"
                    :cell-id="item.workshopId"
                    :displayValue="getDisplayValue(item.type, types, 'text')"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.staffId="{item}">
                <SelectCell
                    :ref="`group-${item.workshopId}`"
                    :edit-active="item.active"
                    :items="computedUsers"
                    label="Staff"
                    :editableComponent="'v-autocomplete'"
                    :isRequired="true"
                    :rules="[required]"
                    v-model="item.staffId"
                    :cell-id="item.workshopId"
                    :displayValue="getStaffDisplay(item.staffId, computedUsers, 'name')"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.attendees="{item}">
                <SelectCell
                    :ref="`group-${item.workshopId}`"
                    :edit-active="item.active"
                    :cell-id="item.workshopId"
                    v-model="item.attendees"
                    label="Attendees"
                    :items="computedClientNames"
                    :multiple="true"
                    :small-chips="true"
                    :editableComponent="'v-autocomplete'"
                    :isRequired="true"
                    :rules="[required]"
                    :default-value="[]"
                    :show-condensed="true"
                    :display-value="
                        getAttendeesDisplay(item.attendees, computedClientNames, 'name')
                    "
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.editRecord="{item}">
                <div class="editable_table__edit-record ml-2 absolute text-center">
                    <a
                        href="#"
                        v-if="isDeletable"
                        class="mx-1 bg-red-light"
                        @click.prevent="deleteHandler(item)"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>

                    <a
                        href="#"
                        v-if="item.active"
                        class="mr-1 bg-orange-light"
                        @click.prevent="cancelHandler"
                    >
                        <fa-icon icon="times" class="text-orange" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        class="hover:text-primary bg-primary-lightest"
                        :disabled="saving"
                        @click.prevent="saveHandler(item)"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled && isAddable">
                <v-btn depressed class="mt-2 v-btn--flat inline" color="success" @click="addNewRow">
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import cloneDeep from 'clone-deep'
import {format} from 'date-fns'
import {
    DataTableHelper,
    EditableDataTableHelper
} from '@/components/shared/mixins/editableDataTableMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {computed, ref} from '@vue/composition-api'
import {getFullName} from '@/utils/formatters'
import {useModalStore} from '@/stores/useModalStore'
import sortItems from '@/components/shared/mixins/sortItems'
import {FormRules} from '@/components/shared/mixins/formMixins'
import {SharedMixin} from '@/helpers/displayValue'
import {WORKSHOP_TYPES} from '@/utils/constants'
import DatePickerCell from '@/components/partials/DatePickerCell'
import EditableCell from '@/components/partials/EditableCell'
import EditableNumberCell from '@/components/partials/EditableNumberCell'
import TimePickerCell from '@/components/partials/TimePickerCell'
import SelectCell from '@/components/partials/SelectCell'
import DatetimePickerCell from '@/components/partials/DatetimePickerCell'
import {workshopService} from '@/services/workshop.service'

export default {
    name: 'StuniWorkshopsTable',
    components: {
        DatePickerCell,
        EditableCell,
        EditableNumberCell,
        TimePickerCell,
        SelectCell,
        DatetimePickerCell
    },
    mixins: [
        DataTableHelper,
        EditableDataTableHelper,
        MessageDialog,
        sortItems,
        FormRules,
        SharedMixin
    ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        users: {
            type: Array,
            default: () => []
        },
        clientNames: {
            type: Array,
            default: () => []
        },
        total: {
            type: Number,
            default: 5
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 10
        },
        sort: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        isDeletable: {
            type: Boolean,
            default: true
        },
        isAddable: {
            type: Boolean,
            default: true
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        locationProgramId: Number
    },
    emits: ['update-pagination', 'reload-workshops'],
    data() {
        return {
            saving: false,
            emptyText: 'No groups found for the search criteria',
            duration: 3000,
            type: '',
            message: '',
            tableDataArr: [],
            itemKey: 'workshopId',
            itemsPerPage: 10,
            page: 1,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            sortBy: [],
            sortDesc: [true],
            refsToCheck: ['date', 'time', 'type'],
            defaultWorkshop: {
                date: format(new Date(), 'yyyy-MM-dd'),
                time: null,
                type: null,
                staffId: null,
                attendees: null,
                locationProgramId: null
            },
            timeFormat: 'h:mm a',
            locationProgramItems: []
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
        this.locationProgramItems = this.loggedInUser.locationProgramItems.filter((item) => {
            let itemSections = item.text?.split(' ')
            if (
                itemSections?.at(-1) === 'PALM' ||
                itemSections?.at(-1) === 'CALM' ||
                item.text === 'ACT - Mentoring'
            ) {
                return {...item, label: item.text}
            }
        })
    },
    methods: {
        rowClickHandler(obj, e) {
            // if we just cancelled the edit of the row then reset and cancel the edit
            if (this.editCancelled || this.currentNewId != null || this.activeRowId != null) {
                this.editCancelled = false
                return
            } else if (this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.currentRow = obj

            e.select(true)
            this.currentTableRow = e
        },
        itemSelected({item, value}) {
            if (value) {
                this.currentRow = item
                this.currentTableRow = item
                this.selected = [this.currentRow]
            }
        },
        getStaffDisplay(staffId, displayList, prop = 'label') {
            if (!displayList || displayList.length === 0 || !staffId) return ''
            const displayValue = displayList?.find((t) => t.userId === staffId)
            return getFullName(displayValue)
        },
        getAttendeesDisplay(clientIds, displayList, prop = 'label') {
            // const ids = clientIds.split(',')
            if (!clientIds || !clientIds.length) return ''
            let displayValue = ''
            clientIds.forEach((id, index) => {
                const displayItem = displayList?.find((t) => +t.clientId === +id)
                displayValue += getFullName(displayItem)
                if (index < clientIds.length - 1) displayValue += ', '
            })
            return displayValue
        },
        getDefaultNewObject() {
            return {
                ...cloneDeep(this.defaultWorkshop)
            }
        },
        getDisplayLocationProgram(programId, displayList, prop = 'label') {
            if (!displayList || displayList.length === 0 || !programId) return ''
            const displayValue = displayList?.find((t) => t.value === programId)
            return displayValue != null ? displayValue[prop] : ''
        },
        getLocationProgram(id, arr) {
            let result
            arr.map((e) => {
                if (id === e.value) result = e.text
            })
            return result
        },
        filterLocationProgramId(LocationProgram, arr) {
            let result
            arr.filter((e) => {
                if (LocationProgram === e.text) result = e.value
            })
            return result
        },
        validateInputs(id) {
            const forms = this.refsToCheck.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`]?.validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        // async addNewActionPlan() {
        //     if (this.activeRowId) {
        //         const result = await this.validateInputs(this.activeRowId)
        //         if (result && result.includes(false)) {
        //             this.showDiscardMessage(true)
        //             return false
        //         }
        //     }
        //     this.addingNewRow = true
        //     await this.addNewRow('workshopId', this.saveHandler)
        //     this.selection = [this.activeRowObj]
        // },
        toggleEdit(val) {
            if (
                (this.activeRowId && Number(this.activeRowId) === Number(val)) ||
                (this.currentNewId && Number(this.currentNewId) === Number(val)) ||
                !this.isEditable
            )
                return
            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        resetTableRow() {
            // if an existing row is being edited
            if (!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map((item) => {
                    if (item.workshopId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter((item) => {
                    if (item.workshopId !== this.activeRowId) return item
                })
            }
        },
        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        },
        filterTable(data) {
            return data.map((item) => {
                let isActive = item.workshopId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        getActiveRow(data) {
            const activeRow = data?.find((item) => {
                return item.workshopId === this.activeRowId
            })
            return {...activeRow}
        },
        async saveHandler(item) {
            let isSuccess = true
            const {active, ...data} = item
            data.attendees = data.attendees?.join(',')
            data.locationProgramId = this.locationProgramId
            // values.forEach(value => { if(!value) isSuccess = false })
            // if (isSuccess) {
            // const data = this.getActiveRow(this.tableDataArr)
            // TODO: meed to deal with the edit row
            let result
            // check if the workshop exists in the list
            this.saving = true
            const exists = this.tableData.find((e) => e.workshopId === data.workshopId)
            try {
                if (!exists) {
                    delete data.workshopId
                    // add new
                    result = await workshopService.addWorkshop(data)
                } else {
                    // edit
                    result = await workshopService.updateWorkshop(data)
                }
            } catch (error) {
                this.saving = false
                this.$toast.error('An error occurred while saving the workshop')
                return
            } finally {
                this.saving = false
            }


            // now we need to reload the data
            this.$emit('reload-workshops')
        }
    },
    computed: {
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
            staticChoices: (state) => state.app.staticChoices,
            siteSections: (state) => state.app.siteSections
        })
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
            this.tableDataArr.map((e) => {
                e.locationProgram = this.getLocationProgram(
                    e.locationProgramId,
                    this.locationProgramItems
                )
            })
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        }
    },
    setup(props) {
        const modalStore = useModalStore()
        const {openModal} = modalStore
        const types = ref([...WORKSHOP_TYPES])
        const headers = [
            {text: 'Date', align: 'left', value: 'date', class: 'user-status--header'},
            {
                text: 'Time',
                align: 'left',
                value: 'time',
                class: 'first-name--header w-200'
            },
            {
                text: 'Type',
                align: 'left',
                value: 'type',
                class: 'last-name--header w-200'
            },
            {text: 'Facilitator', align: 'left', value: 'staffId', class: ''},
            {text: 'Attendees', align: 'left', value: 'attendees', sortable: false, class: ''},
            {text: '', align: 'left', value: 'editRecord', fixed: true}
        ]

        // const { mutate: enableMfaMutate, loading: enableMfaLoading, onDone: enableMfaOnDone, onError: enableMfaOnError } = useMutation(ENABLE_MFA)
        // const { mutate: disableMfaMutate, loading: disableMfaLoading, onDone: disableMfaOnDone, onError: disableMfaOnError } = useMutation(DISABLE_MFA)

        const computedUsers = computed(
            () => props.users?.map((u) => ({...u, text: getFullName(u), value: u.userId})) || []
        )
        const computedClientNames = computed(() =>
            props.clientNames
                ?.map((c) => ({
                    ...c,
                    text: getFullName(c) || 'Unknown User',
                    value: c.clientId
                }))
                ?.filter((c) => c.text !== 'Unknown User')
        )

        const getNameById = (id) => {
            const user = computedUsers.value.find((t) => t.userId === id)
            return getFullName(user)
        }

        const getCientNameById = (id) => {
            const client = computedClientNames.value.find((t) => t.clientId === id)
            let name = getFullName(client)
            if (!name) name = 'Unknown User'
            return name
        }

        const getAttendeesNames = (attendeesStr) => {
            const ids = attendeesStr?.split(',') || []
            return ids.map((id) => getCientNameById(+id)).join(', ')
        }

        const deleteHandler = (workshop) => {
            openModal({payload: {workshop}, modalName: 'ModalConfirmDeleteWorkshop'})
        }

        return {
            headers,
            types,
            computedUsers,
            computedClientNames,
            deleteHandler,
            getNameById,
            getCientNameById,
            getAttendeesNames
        }
    }
}
</script>
